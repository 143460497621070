import Particle from "../Particle";

export default function Playground() {
	  return (
		<><Particle /><div className="container" style={{ paddingTop: '100px', height: '95vh' }}>
			  <div className="row">
				  <div className="col-md-12">
					  <h1 style={{ color: 'white' }}>Page Not Implemented yet</h1>
				  </div>
			  </div>
		  </div></>
	  );
}